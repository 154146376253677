/* global window requestAnimationFrame CustomEvent */
import { useEffect } from 'react';

const THROTTLE = 500;

export default function useThrottleResize(
  delay = THROTTLE,
  on = 'resize',
  customEventName = 'throttledResize',
  eventTarget,
) {
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    eventTarget = eventTarget || window;

    let isActive = false;

    function dispatch() {
      if (isActive) return;
      isActive = true;

      setTimeout(() => {
        requestAnimationFrame(() => {
          eventTarget.dispatchEvent(new CustomEvent(customEventName));
          isActive = false;
        });
      }, delay);
    }

    eventTarget.addEventListener(on, dispatch);
    return () => {
      eventTarget.removeEventListener(on, dispatch);
    };
  }, []);
}
