/* eslint-disable consistent-return */
/* global window */
import { useEffect } from 'react';

// custom effect to add an event listener on mounting component
export default function useEventListener(eventName, fn, target) {
  useEffect(() => {
    const node = target || window;

    node.addEventListener(eventName, fn);

    return () => {
      node.removeEventListener(eventName, fn);
    };
  }, []);
}
