import React from 'react';
import { timestampToHourString as hourString } from '../../../../functions/PETScheduler/timeConversions';

/**
 * create a ruler for hours, sized to the window
 * exp
 * @param {} props 
 */
export default function HourGuide({ styles, openMins, closeMins, xScale }) {
  if (!xScale) return null;

  function listHours() {
    const hours = [];

    for (let i = openMins; i <= closeMins; i += 60) {

      const width = closeMins - i > 60 ? xScale(60) : xScale(closeMins - i);
      const style = { width: `${Math.round(width)}px` };
      let className = styles.hourBlock;

      if (width === 0) { // last tick
        className += ` ${styles.last}`;
      }
      if (i === openMins) { // first tick
        className += ` ${styles.first}`;
      }

      const component = (
        <div key={i} className={className} style={style}>
          <span className={styles.hourTick}>
            {hourString(i)}<br />
          </span>
        </div>
      );
      hours.push(component);
    }

    return hours;
  }

  return (
    <div className={styles.scheduleRowHour}>
      {listHours()}
    </div>
  );
}
