/* global window document */
const DEFAULT_X = 1000;
const DEFAULT_Y = 800;

// get height and width of open window
export function getContainerDimensions(defaultX, defaultY, elementId) {
  if (typeof document === 'undefined') {
    return [defaultY, defaultX];
  }

  const el = document.getElementById(elementId);

  // on first render, there won't be an element to measure yet
  if (!el) return [Math.floor(window.innerWidth), Math.floor(window.innerHeight)];

  const { width = defaultX, height = defaultY } = el.getBoundingClientRect();

  return [Math.floor(width), Math.floor(height)];
}
/**
 *
 * @param {*} setX set state fn for width
 * @param {*} setY set state fn for height
 * @param {Object} options xOffset: % reduction of inner width, yOffset: % reduction of inner height
 * defaultX: value of width if window inaccessible, defaultY value of height if inner window inaccessible
 */
export default function useContainerDimensions(setX, setY, elementId, options) {
  const { defaultX = DEFAULT_X, defaultY = DEFAULT_Y } = options || {};

  const [width, height] = getContainerDimensions(defaultX, defaultY, elementId);
  setX(width);
  setY(height);
}
