import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/CleanLayout';
import styles from './styles.module.scss';
import Calculator from '../../components/graphing/calculator/Calculator';

const METADATA = [
  {
    name: 'description',
    content: 'Compare optimal PET scanner schedules before and after SubtlePET',
  },
  {
    name: 'keywords',
    content: 'Subtle Medical, PET scanner, schedule, SubtlePET',
  },
];

// function parseTime(startHour, minutes) {
//   const mins = minutes % 60 > 9 ? minutes % 60 : `${minutes % 60}0`;
//   const hours = parseInt(startHour, 10) + Math.floor(minutes / 60);
//   return `${hours}:${mins}`;
// }

const IndexPage = () => (
  <Layout pageTitle="PET Schedule Calculator | Subtle Medical" metadata={METADATA}>
    <div id="tooltip" className={styles.tooltip} data-visible="false">Tooltip</div>
    <Calculator styles={styles} />
  </Layout>
);

export default IndexPage;
