/**
 * Takes an hour string (2:34) and returns # minutes past 0:00
 * @param {string} hourString
 */
function minutesFromZero(hourString) {
  // parseInt will drop everything
  let hours = parseInt(hourString);

  if (isNaN(hours)) throw new Error('Input is not convertible to a timestamp');

  const re = /\d+:([\d]{2,2})/;
  const matches = re.exec(hourString);
  const minutes = matches && parseInt(matches[1]) || 0;

  if (hours > 24 || hours < 0 || minutes < 0 || minutes > 60)
    throw new Error('Invalid time string');

  const final = hours * 60 + minutes;
  // console.log(hours, minutes, final);
  return final;
}

/**
 * Takes a timestamp in minutes past 0:00 and returns an hour string
 * @param {integer} timestamp
 */
function timestampToHourString(timestamp) {
  const hours = Math.floor(timestamp / 60);
  const minutes = timestamp % 60;
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

// todos -- handle when numebsrs

/**
 * Takes two hour strings, and converts the 2nd one to 24hr format if it
 * is smaller than the first string
 * @param {*} a
 * @param {*} b
 */
function to24Hours(a, b) {
  const aHours = parseInt(a);
  const bHours = parseInt(b);

  if (bHours < aHours) {
    if (typeof b === 'number') return b + 12; // handles cases where a plain number is passed
    const minutes = b.split(':')[1];
    return `${bHours}:${minutes}`;
  }

  return b;
}

module.exports = {
  minutesFromZero,
  timestampToHourString,
  to24Hours,
};
