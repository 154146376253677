import React from 'react';
// import styles from './BallLoader.scss';
/**
 * with customizable styles
 */
function BallLoader({ styles }) {
  return (
    <div className={styles.ballLoaderContainer}>
      <div className={`${styles.ball} ${styles.bounce1}`} />
      <div className={`${styles.ball} ${styles.bounce2}`} />
      <div className={`${styles.ball} ${styles.bounce3}`} />
    </div>
  );
}

export default React.memo(BallLoader);
